import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Radar } from 'react-chartjs-2';

function Radar1() {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get("http://localhost:5000/charts2");
            formatChartData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const formatChartData = (data) => {
        const labels = Object.keys(data);
        const values = Object.values(data).map(val => parseInt(val));

        const chartConfig = {
            labels: labels,
            datasets: [{
                label: 'Radar Chart Data',
                data: values,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgb(255, 99, 132)',
                pointBackgroundColor: 'rgb(255, 99, 132)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgb(255, 99, 132)'
            }]
        };

        setChartData(chartConfig);
    };

    return (
        <div className="col-sm-6">
            <h4 className="text-center text-primary mt-2 mb-3">Radar Chart</h4>
            
            {chartData && (
                <Radar
                    data={chartData}
                    options={{
                        elements: {
                            line: {
                                
                            }
                        }
                    }}
                />
            )}
        </div>
    );
}

export default Radar1;