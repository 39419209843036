
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar ,Line} from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';

function LineChart() {
    const [chartData, setChartData] = useState(null);
    const [countryData, setCountryData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get("http://localhost:5000/chart");
            setCountryData(response.data);
            formatChartData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const formatChartData = (data) => {
        const labels = data.map(item => item.name);
        const budgetData = data.map(item => item.price);
        const backgroundColors = [
            'green', 'red', 'blue', '#FFBF00', '#DE3163',
            'orange', '#40E0D0', '#6495ED', '#CCCCFF',
            '#FFBF00', '#DE3163', '#9FE2BF', '#CD5C5C'
        ];

        const chartConfig = {
            labels: labels,
            datasets: [{
                label: 'In Billions Dollar',
                backgroundColor: backgroundColors,
                borderWidth: 0,
                data: budgetData,
            }]
        };

        setChartData(chartConfig);
    };

    return (
        <div className="container">
            <h4 className="text-center text-primary mt-2 mb-3">Graph Chart in ReactJS</h4>
            <h6 className="text-center text-success mt-2 mb-3">Country By Defence Budget</h6>
            <div className="row mt-3">
                <div className="col-sm-3">
                    <div>
                        <table className="table-bordered graphTable">
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th>Budget</th>
                                </tr>
                            </thead>
                            <tbody>
                                {countryData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>${item.price}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-sm-9">
                    {chartData && (
                       <Line
                       data={chartData}
                       options={{
                           indexAxis: 'x', // Use category scale for x-axis
                           scales: {
                               y: {
                                   beginAtZero: true
                               }
                           }
                       }}
                   />
                    )}
                </div>
            </div>
        </div>
    );
}

export default LineChart;
