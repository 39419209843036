import React from 'react'
import Navbar from '../components/Navbar'
import Sidebar1 from '../components/Sidebar.jsx'

const Layout = ({children}) => {
  return (
    
        <React.Fragment>
            <Navbar></Navbar>
            <div className="columns mt-6">
                <div className="column is-2">
                    <Sidebar1/>

                </div>
                <div className="column has-background-light">
                    <main>{children}</main>
                </div>
                
            </div>

        </React.Fragment>
  )
}

export default Layout