import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./sinolika.css";

const Sinolika = () => {
  const [chart, setChart] = useState([]);

  useEffect(() => {
    getChart();
  }, []);

  const getChart = async () => {
    try {
      const response = await axios.get("http://localhost:5000/charts2");
      setChart(response.data);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  return (
    <div class="container">
      <h2>ΣΥΝΟΛΙΚΑ ΣΤΑΤΙΣΤΙΚΑ ΟΜΑΔΑΣ <small></small></h2>
      <ul class="responsive-table">
        <h1><strong>Atacking <small></small></strong></h1>

        <li class="table-header">
          <div class="col col-4">Goals</div>
          <div class="col col-4">Passes</div>
          <div class="col col-4">Assists</div>
          <div class="col col-4">MissPass</div>
          <div class="col col-4">long pass</div>
          <div class="col col-4">keypass</div>
          <div class="col col-4">Shootsof</div>
          <div class="col col-4">shootons</div>
          <div class="col col-4">sentres</div>
          
          
        </li>
        <li class="table-row">
          <div class="col col-4" data-label="goals">{chart.totalGoals}</div>
          <div class="col col-4" data-label="Επιτθχημενες Πασσεσ">{chart.totalPasses}</div>
          <div class="col col-4" data-label="Assists">{chart.totalAssists}</div>
          <div class="col col-4" data-label="Χαμενεσ πασεσ">{chart.totalMissPasses}</div>
          <div class="col col-4" data-label="μακρυνες πασεσ">{chart.totalLongpass}</div>
          <div class="col col-4" data-label="πασσεσ κλειδια">{chart.totalkeypass}</div>
          <div class="col col-4" data-label="σουτ εκτοσ στοχο">{chart.totalshootsofftarget}</div>
          <div class="col col-4" data-label="σουτ στον στοχου">{chart.totalshootsontarget}</div>
          <div class="col col-4" data-label="Σεντρες">{chart.totalsentres}</div>
          
        </li>
      </ul>
      <ul class="responsive-table">
        <li class="table-header">
        <div class="col col-2">Goals</div>
          <div class="col col-3">Passes</div>
          <div class="col col-4">Assists</div>
        </li>
        <li class="table-row">
          <div class="col col-2" data-label="Αντιπαλη Ομαδα">{chart.totalGoals}</div>
          <div class="col col-3" data-label="Ημερομηνια">{chart.totalMissPasses}</div>
          <div class="col col-4" data-label="Goals">{chart.totalAssists}</div>
        </li>
      </ul>
      <ul class="responsive-table">
        <li class="table-header">
        <div class="col col-2">Goals</div>
          <div class="col col-3">Passes</div>
          <div class="col col-4">Assists</div>
        </li>
        <li class="table-row">
          <div class="col col-2" data-label="Αντιπαλη Ομαδα">{chart.totalGoals}</div>
          <div class="col col-3" data-label="Ημερομηνια">{chart.totalMissPasses}</div>
          <div class="col col-4" data-label="Goals">{chart.totalAssists}</div>
        </li>
      </ul>
      <ul class="responsive-table">
        <li class="table-header">
        <div class="col col-2">Goals</div>
          <div class="col col-3">Passes</div>
          <div class="col col-4">Assists</div>
        </li>
        <li class="table-row">
          <div class="col col-2" data-label="Αντιπαλη Ομαδα">{chart.totalGoals}</div>
          <div class="col col-3" data-label="Ημερομηνια">{chart.totalMissPasses}</div>
          <div class="col col-4" data-label="Goals">{chart.totalAssists}</div>
        </li>
      </ul>
    </div>
  );
};

export default Sinolika;

