import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./App2.css"
const Table = () => {
  const [chart, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const response = await axios.get("http://localhost:5000/charts");
    setProducts(response.data);
  };

  const deleteChart = async (chartId) => {
    await axios.delete(`http://localhost:5000/charts/${chartId}`);
    getProducts();
  };

  return (
    
        
    <div class="table" id="customers_table">
   
    <section class="table__body">
        <table>
        <thead>
          <tr>
            <th>No</th>
            
            <th>gOALS</th>
            <th>ANTIPALOI</th>
            <th>DATE</th>
            <th>Created By</th>
            
            <th>Asists</th>
            <th>Passes</th>
            <th>Miss Pass</th>
            <th>Shoots</th>
            <th>Asists</th>
            <th>Passes</th>
            <th>Miss Pass</th>
            <th>Shoots</th>
            <th>Asists</th>
            <th>Passes</th>
            <th>Miss Pass</th>
            <th>Shoots</th>
            <th>Asists</th>
            <th>Passes</th>
            <th>Miss Pass</th>
            <th>Shoots</th>
            <th>Apodosi</th>
            <th>Actions</th>
            
            

            
          </tr>
        </thead>
            <tbody>
          {chart.map((chart, index) => (
            <tr key={chart.uuid}>
              <td>{index + 1}</td>
              
              <td>{chart.goals}</td>
              <td>{chart.antipaloi}</td>
              <td>{chart.date}</td>
              <td>{chart.user.name}</td>

              <td>{chart.assists}</td>
              <td>{chart.passes}</td>
              <td>{chart.xamenespasses}</td>
              <td>{chart.shootsontarget}</td>
              <td>{chart.apodosi}</td>
              <td>
                
                <button
                  onClick={() => deleteChart(chart.uuid)}
                  className="button is-small is-danger"
                >
                  Delete
                </button>
              </td>
              
              
            </tr>
          ))}
        </tbody>
        </table>
    </section>
</div>
   
  );
};

export default Table;
