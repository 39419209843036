import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddExpected = () => {
  const [xGValues, setXGValues] = useState({
    xG1: 0,
    xG2: 0,
    xG3: 0,
    xG4: 0,
    xG5: 0,
    date: new Date()
  });
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setXGValues({ ...xGValues, [name]: parseFloat(value) || 0 });
  };

  const handleDateChange = (date) => {
    setXGValues({ ...xGValues, date });
  };

  const increment = (field) => {
    setXGValues({ ...xGValues, [field]: xGValues[field] + 1 });
  };

  const decrement = (field) => {
    if (xGValues[field] > 0) {
      setXGValues({ ...xGValues, [field]: xGValues[field] - 1 });
    }
  };

  const saveCharts = async (e) => {
    e.preventDefault();
    try {
      // Define factors for each xG input
      const factors = [0.1, 0.2, 0.3, 0.5, 0.6];
  
      // Calculate the combined xG value by multiplying each xG value by its corresponding factor
      const combinedXG = Object.values(xGValues)
        .slice(0, 5)
        .map((xg, index) => (xg || 0) * factors[index]) // Multiply each xG by its corresponding factor
        .reduce((total, current) => total + current, 0); // Sum up the values
    
      // Send the combined xG and date to the server
      await axios.post("http://localhost:5000/expected", { xG: combinedXG, date: xGValues.date });
      navigate("/");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Charts</h1>
      <h2 className="subtitle">Add New Chart</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={saveCharts}>
              <p className="has-text-centered">{msg}</p>
              {[1, 2, 3, 4, 5].map(index => (
  <div className="field" key={`xG${index}`}>
    <label className="label">xG {index}</label>
    <div className="control">
      <div className="input-group">
        <button
          className="button is-info is-outlined is-small"
          type="button"
          onClick={() => decrement(`xG${index}`)}
        >
          -
        </button>
        <input
          style={{ width: "30px" }} // Adjusted input width
          type="text"
          className="input is-small"
          name={`xG${index}`}
          value={xGValues[`xG${index}`]}
          onChange={handleChange}
          placeholder={`xG ${index}`}
        />
        <button
          className="button is-info is-outlined is-small"
          type="button"
          onClick={() => increment(`xG${index}`)}
        >
          +
        </button>
      </div>
    </div>
  </div>
))}

              <div className="field">
                <label className="label">Date</label>
                <div className="control">
                  <input
                    type="date"
                    className="input is-small" // Change size to small
                    style={{ width: "150px" }} // Adjust width here
                    value={xGValues.date}
                    onChange={(e) => handleDateChange(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success is-small"> {/* Change button size to small */}
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddExpected;
