import { BrowserRouter,Routes,Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Register from "./components/Register";
import LineChart from "./components/LineChart";
import Users from "./pages/Users";
import Products from "./pages/Products";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import AddProduct from "./pages/AddProduct";
import EditProduct from "./pages/EditProduct";
import Chartone from "./pages/Chartone";
import Stats from "./pages/Stats";
import Radar from "./pages/Radar"
import AddStat from "./pages/AddStat";
import AddExpected from "./pages/AddExpected";
import Sinolikastat from "./pages/Sinolikastat";
//import "./App.css";
import Table2 from "./pages/Table";
import EditStats from "./pages/EditStats";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
       <Routes>
        <Route path ="/" element={<Login />}></Route>
        <Route path ="/dashboard" element={<Dashboard />}></Route>
        <Route path ="/lin" element={<LineChart />}></Route>
        <Route path ="/users" element={<Users />}></Route>
        <Route path ="/users/add" element={<AddUser />}></Route>
        <Route path ="/users/edit/:id" element={<EditUser />}></Route>
        <Route path ="/sinolika" element={<Sinolikastat />}></Route>
        <Route path ="/products/add" element={<AddProduct />}></Route>
        <Route path ="/products/edit/:id" element={<EditProduct />}></Route>
        <Route path ="/stats/edit/:id" element={<EditStats />}></Route>
        <Route path ="/register" element={<Register />}></Route>
       
        

        <Route path ="/line" element={<Chartone />}></Route>
        <Route path ="/stats" element={<Stats />}></Route>
        <Route path ="/stats/add" element={<AddStat />}></Route>
        <Route path ="/radar" element={<Radar />}></Route>
        <Route path ="/table" element={<Table2 />}></Route>
        <Route path ="/expected" element={<AddExpected />}></Route>
        
       </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
