import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./App3.css";
import jsPDF from "jspdf";
import "jspdf-autotable";

const StatList = () => {
  const [chart, setChart] = useState([]);

  useEffect(() => {
    getChart();
  }, []);

  const getChart = async () => {
    try {
      const response = await axios.get("http://localhost:5000/charts");
      setChart(response.data);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  const deleteChart = async (chartId) => {
    try {
      await axios.delete(`http://localhost:5000/charts/${chartId}`);
      getChart();
    } catch (error) {
      console.error("Error deleting chart:", error);
    }
  };


  return (
    <div className="container">
      <h1 className="title">ΣΤΑΤΙΣΤΙΚΑ</h1>

      <Link to="/stats/add" className="button is-primary mb-2">
        Προσθεσέ νεα στατιστικα
      </Link>

      <div className="outer-wrapper">
        <div className="table-wrapper">
          <table id="stat-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Αντιπαλη Ομαδα</th>
                <th>Ημερομηνια</th>
                <th>Goals</th>
                <th>Asists</th>
                <th>Πασσες</th>
                <th>Χαμμενες Πασσες</th>
                <th>Ανεπυτεχης Πασσες</th>
                <th>Μακρυνες Πασσες</th>
                <th>Πασσες Κλειδια</th>
                <th>Σεντρες</th>
                <th>Στημενες Φασεις</th>
                <th>xamenikatoxi</th>
                <th>klepsimo</th>
                <th>kefalia</th>
                <th>keyerror</th>
                <th>tripla</th>
                <th>shootsofftarget</th>
                <th>shootsontarget</th>
                <th>xG</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {chart.map((chartItem, index) => (
                <tr key={chartItem.uuid}>
                  <td>{index + 1}</td>
                  <td>{chartItem.antipaloi}</td>
                  <td>{chartItem.date}</td>
                  <td>{chartItem.goals}</td>
                  <td>{chartItem.assists}</td>
                  <td>{chartItem.passes}</td>
                  <td>{chartItem.xamenespasses}</td>
                  <td>{chartItem.anepitixispasses}</td>
                  <td>{chartItem.longpass}</td>
                  <td>{chartItem.keypass}</td>
                  <td>{chartItem.sentres}</td>
                  <td>{chartItem.sthmenesfaseis}</td>
                  <td>{chartItem.xamenikatoxi}</td>
                  <td>{chartItem.klepsimo}</td>
                  <td>{chartItem.kefalia}</td>
                  <td>{chartItem.keyerror}</td>
                  <td>{chartItem.tripla}</td>
                  <td>{chartItem.shootsofftarget}</td>
                  <td>{chartItem.shootsontarget}</td>
                  <td>{chartItem.xG}</td>
                  <td>
                  <Link
                  to={`/stats/edit/${chart.uuid}`}
                  className="button is-small is-info"
                >
                  Edit
                </Link>
                    <button
                      onClick={() => deleteChart(chartItem.uuid)}
                      className="button is-small is-danger"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
  );
};

export default StatList;
