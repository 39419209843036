import React,{useEffect} from 'react'
import { usePDF } from 'react-to-pdf';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMe } from '../features/authSlice';
import Radar1 from '../components/Radar';

import Layout
from './Layout'
const Radar = () => {
  const dispatch =useDispatch();
  const navigate = useNavigate(); 
  const {isError} =useSelector((state=> state.auth) );
  useEffect(()=>{
    dispatch(getMe());
  },[dispatch]);

  useEffect(()=>{
   if(isError){
    navigate("/");
   }
  },[isError,navigate]);
  const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
  const options = {
    // default is `save`
    method: 'open',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.HIGH,
    page: {
       // margin is in MM, default is Margin.NONE = 0
       margin: Margin.SMALL,
       // default is 'A4'
       format: 'letter',
       // default is 'portrait'
       orientation: 'landscape',
    },
    canvas: {
       // default is 'image/jpeg' for better size performance
       mimeType: 'image/png',
       qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
       // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
       pdf: {
          compress: true
       },
       // see https://html2canvas.hertzen.com/configuration for more options
       canvas: {
          useCORS: true
       }
    },
 };
 
 // you can use a function to return the target element besides using React refs
 const getTargetElement = () => document.getElementById('content-id');
  return (
    <Layout>
      
<div className='container' style={{float: "none", width:"50%" ,height:"50%", backgroundColor:"lightgrey"}} >    
 <div id="content-id"  style={{ width:"100%" ,height:"50%",  position: "relative"}}>
 <Radar1/></div>
</div> 
<button onClick={() => generatePDF(getTargetElement, options)}>
            Generate PDF
         </button>

        
      </Layout>
  )
}

export default Radar