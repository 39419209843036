import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMe } from '../features/authSlice';
import Chartsassists from '../components/Chartsassists';
import Charts1 from '../components/Charts1';
import Chart from '../components/Charts';
import Chartshoots from '../components/Chartshoots';
import Layout from './Layout';
import './chartone.css';
const Chartone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState('all');
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate('/');
    }
  }, [isError, navigate]);

  return (
    <Layout>
      <div className="container">
        <div style={{ textAlign: 'center' }}>
          <label htmlFor="chartFilter" style={{ marginRight: '10px' }}>Filter:</label>
          <select
            id="chartFilter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            style={{ padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
          >
            <option value="all">All Charts</option>
            <option value="chart">Chart</option>
            <option value="chart1">Chart 1</option>
            <option value="assists">Assists Chart</option>
            <option value="chartshoot">Chartshoots</option>
          </select>
        </div>
        <div className="charts-container">
          {filter === 'all' || filter === 'chart' ? (
            <div className="chart-item">
              <Chart />
            </div>
          ) : null}
          {filter === 'all' || filter === 'chart1' ? (
            <div className="chart-item">
              <Charts1 />
            </div>
          ) : null}
          {filter === 'all' || filter === 'assists' ? (
            <div className="chart-item">
              <Chartsassists />
            </div>
          ) : null}
          {filter === 'all' || filter === 'chartshoot' ? (
            <div className="chart-item">
              <Chartshoots />
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default Chartone;
