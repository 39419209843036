import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar,Line } from 'react-chartjs-2';

function LineChart() {
    const [chartData, setChartData] = useState(null);
    const [countryData, setCountryData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get("http://localhost:5000/charts");
            setCountryData(response.data);
            formatChartData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const formatChartData = (data) => {
        const labels = data.map(item => item.antipaloi);
        const numericalValues = data.map(item => item.passesPercentage);
        const marios = data.map(item => item.name);

        const chartConfig = {
            labels: labels,
            datasets: [{
                label: 'percentage ',
                fill: false,
                borderColor: 'green',
                
                data: numericalValues,
            }]
        };

        setChartData(chartConfig);
    };

    return (
        
            
            
                <div className="col-sm-6">
                    <h4 className="text-center text-primary mt-2 mb-3"></h4>
                    {chartData && (
                        <Line
                            data={chartData}
                            options={{
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                        min: 0,
                                        max: 100,
                                    }
                                },
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'bottom'
                                    },
                                    title: {
                                        display: true,
                                        text: "Percentage",
                                        fontSize: 20
                                    }
                                }
                            }}
                        />
                    )}
                </div>
            
        
    );
}

export default LineChart;