import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line,Bar } from 'react-chartjs-2';

function Chartsassists() {
    const [chartData, setChartData] = useState(null);
    const [countryData, setCountryData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get("http://localhost:5000/charts");
            setCountryData(response.data);
            formatChartData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const formatChartData = (data) => {
        const labels = data.map(item => item.antipaloi);
        const assists = data.map(item => item.assists);
        const marios = data.map(item => item.name);

        const chartConfig = {
            labels: labels,
            datasets: [{
                label: 'Asists ',
                fill: false,
                borderColor: 'black',
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(255, 205, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(201, 203, 207, 0.2)'
                  ],
                
                data: assists,
            }]
        };

        setChartData(chartConfig);
    };

    return (
        
            
            
                <div className="col-sm-6">
                    <h4 className="text-center text-primary mt-2 mb-3"></h4>
                    {chartData && (
                        <Bar
                            data={chartData}
                            options={{
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                        min: 0,
                                        max: 10,
                                    }
                                },
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'bottom'
                                    },
                                    title: {
                                        display: true,
                                        text: "Asists",
                                        fontSize: 20
                                    }
                                }
                            }}
                        />
                    )}
                </div>
            
        
    );
}

export default Chartsassists;