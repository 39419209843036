import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import malakia from "./gipedaki.jpg"
import Grid from "@material-ui/core/Grid";
const FormAddStats = () => {
  
  const [goals, setGoals] = useState(0);
  const [antipaloi, setAntipaloi] = useState("");
  const [date, setDate] = useState("");
  const [assists, setAssists] = useState(0);
  const [passes, setPasses] = useState("");
  const [xamenespasses, setXamenespasses] = useState("");
  const [shootsontarget, setshootsontarget] = useState("");

  const [anepitixispasses, setAnepitixispasses] = useState("");
  const [longpass, setLongpass] = useState("");
  const [keypass, setKeypass] = useState("");
  const [sentres, setSentres] = useState("");
  const [sthmenesfaseis, setSthmenesfaseis] = useState("");
  const [klepsimo, setKlepsimo] = useState("");
  const [kefalia, setKefalia] = useState("");
  const [keyerror, setKeyerror] = useState("");
  const [tripla, setTripla] = useState("");
  const [shootsofftarget, setShootsofftarget] = useState("");
  const [xamenikatoxi, setXamenikatoxi] = useState("");
  const [apokrouseis, setApokrouseis] = useState(0);
  const [shootspoudektike, setShootpoudektike] = useState(0);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [xGValues, setXGValues] = useState({
    xG1: "",
    xG2: "",
    xG3: "",
    xG4: "",
    xG5: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setXGValues({ ...xGValues, [name]: value });
  };
  const increment = () => {
    setGoals(goals + 1);
  };

  const decrement = () => {
    if (goals > 0) {
      setGoals(goals - 1);
    }
  };

  const increment1 = () => {
    setApokrouseis(apokrouseis + 1);
  };

  const decrement1 = () => {
    if (apokrouseis > 0) {
      setApokrouseis(apokrouseis - 1);
    }
  };
  const increment2 = () => {
    setShootpoudektike(shootspoudektike + 1);
  };

  const decrement2 = () => {
    if (shootspoudektike > 0) {
      setShootpoudektike(shootspoudektike - 1);
    }
  };



  const saveCharts = async (e) => {
    e.preventDefault();
    try {
      const factors = [0.5, 0.1,0.5, 0.1,0.6];
  
      // Calculate the combined xG value by multiplying each xG value by its corresponding factor
      const combinedXG = [xGValues.xG1, xGValues.xG2,xGValues.xG3, xGValues.xG4,xGValues.xG5]
        .map((xg, index) => (parseFloat(xg) || 0) * factors[index]) // Multiply each xG by its corresponding factor
        .reduce((total, current) => total + current, 0); // Sum up the values
      await axios.post("http://localhost:5000/charts", {
            goals: goals, 
            date: date,
            antipaloi: antipaloi,
            assists: assists,
            passes: passes,
            xamenespasses: xamenespasses,
            anepitixispasses: anepitixispasses,
            longpass: longpass,
            keypass: keypass,
            sentres: sentres,
            sthmenesfaseis: sthmenesfaseis,
            klepsimo: klepsimo,
            kefalia: kefalia,
            keyerror: keyerror,
            tripla: tripla,
            shootsofftarget: shootsofftarget,
            shootsontarget: shootsontarget,
            xamenikatoxi: xamenikatoxi,
            apokrouseis:apokrouseis,
            shootspoudektike:shootspoudektike,
            xG: combinedXG,
      });
      navigate("/stats");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">ΣΤΑΤΙΣΤΙΚΑ</h1>
      <h2 className="subtitle">Προσθηκη νεων στατιστικων</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={saveCharts}>
              <p className="has-text-centered">{msg}</p>
              
              
              <div className="field is-horizontal " >
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">Αντιπαλη Ομαδα</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={antipaloi}
                    onChange={(e) => setAntipaloi(e.target.value)}
                    placeholder="Antipaloi"
                  />
                </div>
              </div>
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">Ημερομηνια</label>
                <div className="control">
                <input
                    type="date"
                    className="input"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                 
                </div>
              </div>
              </div>
              <p className="has-text" style={{ fontFamily: 'Times New Roman, sans-serif', fontSize: '30px', color: 'red' }}>
              <strong><span style={{ textDecoration: 'underline'}}>Attacking</span></strong> 
</p>

              
              
              <div className="field is-horizontal " >
              <div className="field">
  <label className="label" style={{ marginLeft: '20px' }}>Goals</label>
  <div className="field-body" style={{ marginLeft: '20px' }}>
    <div className="field has-addons">
      <div className="control">
        <button
          type="button"
          className="button is-info is-outlined is-small"
          onClick={decrement}
        >
          -
        </button>
      </div>
      <div className="control">
        <input
          style={{ width: "30px" }}
          type="text"
          className="input is-small"
          value={goals}
          onChange={handleChange}
          placeholder="Goals"
        />
      </div>
      <div className="control">
        <button
          type="button"
          className="button is-info is-outlined is-small"
          onClick={increment}
        >
          +
        </button>
      </div>
    </div>
  </div>
</div>
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">Assists</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={assists}
                    onChange={(e) => setAssists(e.target.value)}
                    placeholder="Assists"
                  />
                </div>
              </div>
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">Passes</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={passes}
                    onChange={(e) => setPasses(e.target.value)}
                    placeholder="Passes"
                  />
                </div>
              </div>
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">Miss Pass</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={xamenespasses}
                    onChange={(e) => setXamenespasses(e.target.value)}
                    placeholder="Miss Pass"
                  />
                </div>
              </div>


              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">AnepitixisPasses</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={anepitixispasses}
                    onChange={(e) => setAnepitixispasses(e.target.value)}
                    placeholder="ANEPITIXIS PASSES"
                  />
                </div>
              </div>
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">keypass</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={keypass}
                    onChange={(e) => setKeypass(e.target.value)}
                    placeholder="KEY PASSES"
                  />
                </div>
              </div>
              </div>
              
              <div className="field is-horizontal " >
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">Shoots on Target</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={shootsontarget}
                    onChange={(e) => setshootsontarget(e.target.value)}
                    placeholder="Shoots on Target"
                  />
                </div>
              </div>


              
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label" >SHOOTSOFTARGET</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={shootsofftarget}
                    onChange={(e) => setShootsofftarget(e.target.value)}
                    placeholder="Assists"
                  />
                </div>
              </div>
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label" >Long Pass</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={longpass}
                    onChange={(e) => setLongpass(e.target.value)}
                    placeholder="LONG PASS"
                  />
                </div>
              </div>
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label" >SENTRA</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={sentres}
                    onChange={(e) => setSentres(e.target.value)}
                    placeholder="SENTRES"
                  />
                </div>
              </div>
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label" >STIMENI FASH</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={sthmenesfaseis}
                    onChange={(e) => setSthmenesfaseis(e.target.value)}
                    placeholder="STIMENI FASH"
                  />
                </div>
              </div>
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">TRIPLA</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={tripla}
                    onChange={(e) => setTripla(e.target.value)}
                    placeholder="tripla"
                  />
                </div>
              </div>




              </div>
              <p className="has-text" style={{ fontFamily: 'Times New Roman, sans-serif', fontSize: '30px', color: 'red' }}>
              <strong><span style={{ textDecoration: 'underline'}}>Defending</span></strong> 
</p>
              <div className="field is-horizontal " >
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">xameni katoxi</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={xamenikatoxi}
                    onChange={(e) => setXamenikatoxi(e.target.value)}
                    placeholder="xamenikatoxi"
                  />
                </div>
              </div>

              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">KLEPSIMO</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={klepsimo}
                    onChange={(e) => setKlepsimo(e.target.value)}
                    placeholder="KLEPSMIO"
                  />
                </div>
              </div>
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">KEFALIA</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={kefalia}
                    onChange={(e) => setKefalia(e.target.value)}
                    placeholder="kefalia"
                  />
                </div>
              </div>
              <div className="field" style={{ marginLeft: '20px' }}>
                <label className="label">key error</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={keyerror}
                    onChange={(e) => setKeyerror(e.target.value)}
                    placeholder="KEY ERROR"
                  />
                </div>
              </div>
              </div>
              


              <p className="has-text" style={{ fontFamily: 'Times New Roman, sans-serif', fontSize: '30px', color: 'red' }}>
              <strong><span style={{ textDecoration: 'underline'}}>Map</span></strong> 
</p>

              <Grid container spacing={2}>
       
          <div style={{ position: "relative", width: "100%" }}>
            <img
              //src="https://picsum.photos/1200/600"
              src={malakia}
              //source... https://giphy.com/gifs/corazon-cuore-misterthoms-YewmDPD4ymnaQTkEFM
              alt="test"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                height: "auto"
              }}
            />

            {/* -------BUTTONS------ */}
            
            
            
            
              <p className="has-text-centered">{msg}</p>
              
              
              <div className="field" style={{
                    position: "absolute",
                    top: "20%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "orchid",
                    borderColor: "orchid"
                  }}>
                <label className="label" 
                >xG 1</label>
                <div className="control">
                <input
                    type="text"
                    className="input"
                    name="xG1"
                    value={xGValues.xG1}
                    onChange={handleChange}
                    placeholder="xG 1"
                  />
                </div>
              </div>
              
             
              <div className="field" 
              style={{
                position: "absolute",
                top: "20%",
                left: "20%",
                transform: "translate(-50%, -50%)",
                color: "orchid",
                borderColor: "orchid"
              }}>
                <label className="label">Xg2</label>
                <div className="control">
                <input
                    type="text"
                    className="input"
                    name="xG2"
                    value={xGValues.xG2}
                    onChange={handleChange}
                    placeholder="xG 2"
                  />
                  
                </div>
              </div>
              
              <div className="field" 
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "orchid",
                borderColor: "orchid"
              }}>
                <label className="label">Xg3</label>
                <div className="control">
                <input
                    type="text"
                    className="input"
                    name="xG3"
                    value={xGValues.xG3}
                    onChange={handleChange}
                    placeholder="xG 3"
                  />
                  
                </div>
              </div>


              <div className="field" 
              style={{
                position: "absolute",
                top: "50%",
                left: "80%",
                transform: "translate(-50%, -50%)",
                color: "orchid",
                borderColor: "orchid"
              }}>
                <label className="label">Xg4</label>
                <div className="control">
                <input
                    type="text"
                    className="input"
                    name="xG4"
                    value={xGValues.xG4}
                    onChange={handleChange}
                    placeholder="xG 4"
                  />
                  
                </div>
              </div>

              <div className="field" 
              style={{
                position: "absolute",
                top: "50%",
                left: "20%",
                transform: "translate(-50%, -50%)",
                color: "orchid",
                borderColor: "orchid"
              }}>
                <label className="label">Xg5</label>
                <div className="control">
                <input
                    type="text"
                    className="input"
                    name="xG5"
                    value={xGValues.xG5}
                    onChange={handleChange}
                    placeholder="xG 5"
                  />
                  
                </div>
              </div>
              <div className="field" 
    style={{
        position: "absolute",
        top: "70%",
        left: "20%",
        transform: "translate(-50%, -50%)",
        color: "orchid",
        borderColor: "orchid"
    }}>
    
</div>
<p className="has-text" style={{ fontFamily: 'Times New Roman, sans-serif', fontSize: '30px', color: 'red' }}>
              <strong><span style={{ textDecoration: 'underline'}}>Goalkeeping</span></strong> 
</p>
<div className="field is-horizontal " >
              <div className="field">
  <label className="label" style={{ marginLeft: '20px' }}>Αποκρουσεις</label>
  <div className="field-body" style={{ marginLeft: '20px' }}>
    <div className="field has-addons">
      <div className="control">
        <button
          type="button"
          className="button is-info is-outlined is-small"
          onClick={decrement1}
        >
          -
        </button>
      </div>
      <div className="control">
        <input
          style={{ width: "30px" }}
          type="text"
          className="input is-small"
          value={apokrouseis}
          onChange={handleChange}
          placeholder="Αποκρουσεις"
        />
      </div>
      <div className="control">
        <button
          type="button"
          className="button is-info is-outlined is-small"
          onClick={increment1}
        >
          +
        </button>
      </div>
    </div>
  </div>
</div>
<div className="field">
  <label className="label" style={{ marginLeft: '20px' }}>Σουτ που Δεχτηκε</label>
  <div className="field-body" style={{ marginLeft: '20px' }}>
    <div className="field has-addons">
      <div className="control">
        <button
          type="button"
          className="button is-info is-outlined is-small"
          onClick={decrement2}
        >
          -
        </button>
      </div>
      <div className="control">
        <input
          style={{ width: "30px" }}
          type="text"
          className="input is-small"
          value={shootspoudektike}
          onChange={handleChange}
          placeholder="Σουτ που Δεχτηκε"
        />
      </div>
      <div className="control">
        <button
          type="button"
          className="button is-info is-outlined is-small"
          onClick={increment2}
        >
          +
        </button>
      </div>
    </div>
  </div>
</div>

</div>

              
            
          </div>
          
          <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                  </div>
              </div>
      </Grid>



      

              
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddStats;