import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';


function DropdownSplitButton({ buttonText, buttonIcon, dropdownItems }) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const closeDropdown = (e) => {
    if (!dropdownRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <button className="split-button" onClick={toggleDropdown}>
        <span>{buttonText}</span>
        <img src={buttonIcon} alt="Button Icon" />
      </button>

      {open && (
        <div className="dropdown-menu">
          <ul>
            {dropdownItems.map((item, index) => (
              <li key={index} className="dropdown-item">
                <Link to={item.link}>{item.text}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default DropdownSplitButton;
