import React from 'react'
import {useSelector} from "react-redux"
import malakia from "./gipedaki.jpg"
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ReactDOM from "react-dom";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import PageviewIcon from "@material-ui/icons/Pageview";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {IncDecCounter} from "../features/IncDecCounter"
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Card } from '@material-ui/core';
import './welcome.css';
const Welcome = () => {
  
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

 
  const {user} = useSelector((state) => state.auth);
  return (
   
    <div >
      
        <h1 className='title'>Dashboard</h1>
        <h2 className='subtitle' > Welcome back..<strong style={{color:"black"}}> {user && user.name}</strong></h2>
        
        
        

              
      <Grid container spacing={2}>
       
          

           
      
      <div className='user-guide' >
            <h3>User Guide</h3>
            <table>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>Product Name</td>
                </tr>
                <tr>
                  <td>Price</td>
                  <td>Price of the product</td>
                </tr>
                <tr>
                  <td>Goals</td>
                  <td>Number of goals</td>
                </tr>
                <tr>
                  <td>Antipaloi</td>
                  <td>Antipaloi value</td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>Date of the entry</td>
                </tr>
                <tr>
                  <td>Assists</td>
                  <td>Number of assists</td>
                </tr>
                <tr>
                  <td>Passes</td>
                  <td>Number of passes</td>
                </tr>
                <tr>
                  <td>Miss Pass</td>
                  <td>Number of missed passes</td>
                </tr>
                <tr>
                  <td>Shoots on Target</td>
                  <td>Number of shoots on target</td>
                </tr>
              </tbody>
            </table>

            </div>
            <div className='user-guide-container' >
            <h3>User Guide</h3>
            <table>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>Product Name</td>
                </tr>
                <tr>
                  <td>Price</td>
                  <td>Price of the product</td>
                </tr>
                <tr>
                  <td>Goals</td>
                  <td>Number of goals</td>
                </tr>
                <tr>
                  <td>Antipaloi</td>
                  <td>Antipaloi value</td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>Date of the entry</td>
                </tr>
                <tr>
                  <td>Assists</td>
                  <td>Number of assists</td>
                </tr>
                <tr>
                  <td>Passes</td>
                  <td>Number of passes</td>
                </tr>
                <tr>
                  <td>Miss Pass</td>
                  <td>Number of missed passes</td>
                </tr>
                <tr>
                  <td>Shoots on Target</td>
                  <td>Number of shoots on target</td>
                </tr>
              </tbody>
            </table>
          
          </div>

            
            
            

            
            
          
          
      </Grid>
      
        
        </div>
        
    
  )
}

export default Welcome