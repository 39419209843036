import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import background from "../utils/sh.jpg";
import { Link } from "react-router-dom";
const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confPassword: '',
    role: 'user'
  });
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize navigate function

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password, confPassword, role } = formData;
    if (password !== confPassword) {
      setError('Password dan Confirm Password tidak cocok');
      return;
    }

    try {
      await axios.post('http://localhost:5000/users1', {
        name,
        email,
        password,
        confPassword,
        role
      });
      alert('Register Berhasil');
      // Redirect to login page
      navigate('/');
    } catch (error) {
      setError(error.response.data.msg);
    }
  };

  return (
    <body background={background}>
    <section style={{ background: `url(${background})` }} className="hero is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-4">
              <form onSubmit={handleSubmit} className="box">
                {error && <p className="has-text-centered">{error}</p>}
                <h1 className="title is-2">Register</h1>
                <div className="field">
                  <label className="label">Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="input"
                  />
                </div>
                <div className="field">
                  <label className="label">Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="input"
                  />
                </div>
                <div className="field">
                  <label className="label">Password:</label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="input"
                  />
                </div>
                <div className="field">
                  <label className="label">Confirm Password:</label>
                  <input
                    type="password"
                    name="confPassword"
                    value={formData.confPassword}
                    onChange={handleChange}
                    className="input"
                  />
                </div>
                <button type="submit" className="button is-success is-fullwidth">Register</button>
                <div className="field mt-3">
                  <Link to ="/"
                    type="submit"
                    className="button is-danger is-fullwidth"
                  >
                    {"Go to Login"}
                  </Link>
                </div>
              </form>
              
            </div>
            
          </div>
        </div>
      </div>
    </section>
    </body>
  );
};

export default Register;
